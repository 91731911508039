import React, { Component } from 'react';
import './index.css';

import styled from '@emotion/styled';

import Footer from '../components/Footer';
import { colors } from '../theme/colors';
import Header from '../components/Header';

const BackgroundContainer = styled.div`
  bottom: 0;
  background-color: ${colors.indigo};
  left: 0;
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 0;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
`;

interface Props {}
interface State {
  headIsVisble: boolean;
}

export default class App extends Component<Props, State> {
  render() {
    return (
      <BackgroundContainer>
        <ContentContainer>
          <Header />
        </ContentContainer>
        <Footer />
      </BackgroundContainer>
    );
  }
}

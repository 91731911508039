import React, { Component } from 'react';

import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from '../theme/colors';

const flameContainerHeight = 250;
const particleSize = 8;
const animationDuration = 3;
const numberOfParticles = 80;

const FlameContainer = styled.div`
  bottom: 0;
  filter: 5px;
  left: -5%;
  position: absolute;
  width: 110%;
  height: ${flameContainerHeight}px;
`;

const fireAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(0) scale(1);
  }
  25% {
    opacity: 1.0;
  }
  100% {
    opacity: 0;
    transform: translateY(${-flameContainerHeight / 2}px) scale(0);
  }
`;

interface ParticleProps {
  containerWidth: number;
  containerHeight: number;
  index: number;
}

const Particle = styled.div<ParticleProps>`
  animation: ${fireAnimation} ${animationDuration}s ease-in infinite;
  background-image: radial-gradient(${colors.orange}, ${colors.indigoTransparent});
  border-radius: 50%;
  opacity: 0;
  mix-blend-mode: screen;
  position: absolute;
  bottom: 0;
  width: ${particleSize}px;
  height: ${particleSize}px;

  animation-delay: ${() => `${animationDuration * Math.random()}s`};
  left: ${props => {
    const { containerWidth, index } = props;
    return `${Math.round(((containerWidth - particleSize) * index) / numberOfParticles)}px`;
  }};
`;

interface State {
  container: {
    width: number;
  };
}

export default class Footer extends Component<{}, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      container: {
        width: 0
      }
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  shouldComponentUpdate(_: {}, prevState: State) {
    const { container } = this.state;
    const { container: prevContainer } = prevState;
    if (container.width === prevContainer.width) return false;
    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const { innerWidth } = window;
    this.setState({
      container: {
        width: innerWidth + innerWidth * 0.1
      }
    });
  }

  createParticles = () => {
    const { container } = this.state;
    const particles = [];
    for (let i = 0; i < numberOfParticles; i = i + 1) {
      particles.push(<Particle key={`particle-id-${i}`} containerWidth={container.width} index={i} />);
    }
    return particles;
  };

  render() {
    // return <canvas ref={this.canvasRef} />;
    return <FlameContainer>{this.createParticles()}</FlameContainer>;
  }
}

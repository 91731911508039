export const colors = {
  indigo: '#112F41',
  indigoTransparent: 'rgba(17,47,65,0)',
  teal: '#068587',
  seaweed: '#4FB99F',
  seaweedTransparent: (opacity: number) => `rgba(79, 185, 159, ${opacity})`,
  yellow: '#F2B134',
  yellowTransparent: (opacity: number) => `rgba(242, 177, 52, ${opacity})`,
  orange: '#FF9F31',
  red: '#ED553B'
};

import React from 'react';

import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

import fonts from '../theme/fonts';
import { colors } from '../theme/colors';

const glow = (color: string, secondaryColor?: string) => keyframes`
  to {
    color: ${color};
    text-shadow: 0 0 0.1em ${color};
  };
  50% {
    color: ${secondaryColor || color};
    text-shadow: 0 0 0.2em ${color};
  };
  from {
    color: ${color};
    text-shadow: 0 0 0.1em ${color};
  };
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.h1`
  color: ${colors.yellow};
  font-size: 2em;
  text-decoration: none;
  text-shadow: 0 0 0.1em ${colors.yellow};
  ${fonts.dank};
`;

const Subtitle = styled.h2`
  animation: ${glow(colors.seaweed, colors.seaweedTransparent(0.5))} 5s;
  font-size: 1.1em;
  color: ${colors.seaweed};
  margin-top: 5px;
  text-shadow: 0 0 0.1em ${colors.seaweed};
  ${fonts.dank};
`;

export default () => {
  return (
    <HeaderContainer>
      <Header href="/">WYATT MCBAIN</Header>
      <Subtitle>Creative Technologist</Subtitle>
    </HeaderContainer>
  );
};
